import { useIntl } from "react-intl";
import OptionCard from "../OptionCard";
import {
  getRequiredDateFormat,
  DarkModeContext,
} from "@datwyler/shared-components";

const TrainingPerformance = (props) => {
  const { model } = props;
  const intl = useIntl();

  const cardData = {
    training_last_execution_date: getRequiredDateFormat(
      model.training?.executionTime,
      "DD/MM/YY"
    ),
    training_last_execution_time: getRequiredDateFormat(
      model.training?.executionTime,
      "HH:mm:ss"
    ),
    mean_abs_percentage_err: model.training?.meanAbsPercentageError,
    mean_abs_err: model.training?.meanAbsError,
    root_mean_err: model.training?.rootMeanError,
  };

  return <OptionCard title={"Training Performance"} data={cardData} />;
};

export default TrainingPerformance;
