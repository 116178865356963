import { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import { DarkModeContext, BasicButton } from "@datwyler/shared-components";
import StatusChip from "../StatusChip";
import { TRAINING, FORECASTING, FINISHED } from "../../utils/constants";

const ForecastDetailHeader = (props: any) => {
  const { model } = props;
  const { colors }: any = useContext(DarkModeContext);
  const intl = useIntl();

  const handleRetrain = () => {};

  const handlePredict = () => {};

  return (
    <Box sx={{ marginTop: "24px", marginBottom: "24px", display: "flex" }}>
      <Box sx={{ display: "inline-flex", alignItems: "center" }}>
        <Typography
          sx={{
            color: colors.screenHeaderTitle,
            fontFamily: "NotoSans-SemiBold",
            fontSize: "24px",
            fontWeight: 600,
            letterSpacing: 0,
            lineHeight: "34px",
          }}
        >
          {model.id ? model.name : intl.formatMessage({ id: "forecast_setup" })}
        </Typography>
      </Box>
      {model.id && (
        <Box sx={{ display: "inline-flex", flexGrow: 1 }}>
          <Grid container sx={{ marginLeft: "20px", alignItems: "center" }}>
            <Grid xs={10}>
              <StatusChip status={model.status} />
            </Grid>
            <Grid xs={2} sx={{ display: "flex", justifyContent: "right" }}>
              <BasicButton
                variant="outlined"
                onClick={handleRetrain}
                sx={{
                  border: "1px solid " + colors.blueGray300,
                  marginRight: "16px",
                  height: "44px",
                  width: "88px",
                }}
                disabled={
                  model.status === TRAINING || model.status === FORECASTING
                }
              >
                {" "}
                Retrain
              </BasicButton>
              <BasicButton
                variant="contained"
                onClick={handlePredict}
                sx={{ height: "44px", width: "88px" }}
                disabled={model.status !== FINISHED}
              >
                {" "}
                Predict
              </BasicButton>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default ForecastDetailHeader;
