import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import ForecastSetup from "../components/ForecastSetup";
import ForecastDetail from "../components/ForecastDetail";
import ForecastDetailHeader from "../components/ForecastDetailHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { BasicBreadcrumbs, useLoadingGif } from "@datwyler/shared-components";

const ForecastDetails = () => {
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const model = location.state.model || {};

  const backToAnalytics = () => {
    const state = { ...location.state };
    delete state.model;
    navigate("/analytics", {
      state: state,
    });
  };

  return (
    <Box
      sx={{
        paddingTop: "76px",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingBottom: "24px",
      }}
    >
      <LoadingGif />
      <BasicBreadcrumbs
        activePage={model.id ? "ForecastDetails" : "ForecastSetup"}
      />
      <ForecastDetailHeader model={model} />
      {model?.id ? (
        <ForecastDetail model={model} />
      ) : (
        <ForecastSetup backToAnalytics={backToAnalytics} />
      )}
    </Box>
  );
};

export default ForecastDetails;
