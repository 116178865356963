import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useIntl } from "react-intl";
import Filters from "../components/Filters";
import ModelCard from "../components/ModelCard";
import AnalyticTabs from "../components/AnalyticTabs";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BasicBreadcrumbs,
  BasicScreenHeader,
  useLoadingGif,
  DarkModeContext,
} from "@datwyler/shared-components";

const defaultFilters = {
  startDate: null,
  endDate: null,
  statuses: [],
  name: "",
};

const mockModels = [
  {
    id: 1,
    name: "Forecast1",
    status: "IDLE",
    device: {
      id: "a876290d-d6ac-4071-a5de-c41183ca7103",
      type: "Gateway",
      name: "RUTX11 Gateway",
    },
    parameter: "modem_temperature",
    samplingFreq: 60 * 120,
    value: 1,
    startDate: 1711900800,
    endDate: 1711987200,
    // prediction horizon shows how long the data is predicted ( Green)
    // it is calculated by samplingFreq * predictionHorizon
    // eg. samplingFreq(2 hours) * predictionHorizon (10) = 20 hours
    predictionHorizon: 10,
    lookBackPeriod: 1,
    autotune: true,
    training: {
      executionTime: 1713139200,
      meanAbsPercentageError: "N",
      meanAbsError: 12,
      rootMeanError: 23,
    },
    prediction: {
      executionTime: 1713139200,
      meanAbsPercentageError: "N",
      meanAbsError: 12,
      rootMeanError: 23,
    },
  },
  {
    id: 2,
    name: "Forecast2",
    status: "TRAINING",
  },
  {
    id: 3,
    name: "Forecast3",
    status: "FORECASTING",
  },
  {
    id: 4,
    name: "Forecast4",
    status: "QUEUE",
  },
  {
    id: 5,
    name: "Forecast5",
    status: "FINISHED",
  },
  {
    id: 6,
    name: "Forecast6",
    status: "FINISHED",
  },
  {
    id: 7,
    name: "Forecast7",
    status: "FINISHED",
  },
  {
    id: 8,
    name: "Forecast8",
    status: "FINISHED",
  },
  {
    id: 9,
    name: "Forecast9",
    status: "QUEUE",
  },
  {
    id: 10,
    name: "Forecast10",
    status: "FINISHED",
  },
  {
    id: 11,
    name: "Forecast11",
    status: "FINISHED",
  },
  {
    id: 12,
    name: "Forecast12",
    status: "FINISHED",
  },
  {
    id: 13,
    name: "Forecast13",
    status: "FINISHED",
  },
];

const Analytics = (props: any) => {
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const [filters, setFilters] = useState(defaultFilters);
  const [models, setModels] = useState(mockModels);
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    refetchForecastModels();
  }, [filters]);

  const refetchForecastModels = () => {
    // todo: call api to get models
  };

  const handleAddModel = () => {
    navigateToDetails({});
  };

  const navigateToDetails = (model: any) => {
    navigate("/analytics/forecastDetails/", {
      state: {
        ...location.state,
        model: model,
      },
    });
  };

  const getLowerComponent = () => {
    return (
      <>
        <Divider sx={{ borderColor: colors.dividerColor }} />
        <Box sx={{ display: "inline-flex" }}>
          <Filters setFilters={setFilters} defaultFilters={defaultFilters} />
        </Box>
      </>
    );
  };

  return (
    <Box
      sx={{
        paddingTop: "76px",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingBottom: "24px",
      }}
    >
      <LoadingGif />
      <BasicBreadcrumbs activePage={"Analytics"} />
      <BasicScreenHeader
        title={intl.formatMessage({ id: "menu_analytics" })}
        addButton={{
          label: intl.formatMessage({ id: "add_trend_model" }),
          onClick: handleAddModel,
          width: "144px",
        }}
        LowerComponent={getLowerComponent()}
      />
      <AnalyticTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <Box sx={{ display: "flex", columnGap: "24px", flexWrap: "wrap" }}>
        {models?.map((model) => (
          <ModelCard
            key={model.id}
            model={model}
            navigateToDetails={navigateToDetails}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Analytics;
