import { useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {
  BasicCard,
  DarkModeContext,
  ArrowOutwardIcon,
} from "@datwyler/shared-components";
import StatusChip from "../StatusChip";

const ModelCard = (props: any) => {
  const { model, navigateToDetails } = props;
  const { colors }: any = useContext(DarkModeContext);

  const handleClickArrow = () => {
    navigateToDetails(model);
  };

  return (
    <BasicCard
      sx={{
        minWidth: "202px",
        maxWidth: "calc((100% - 72px) / 4 - 32px)",
        flex: "1 0 20%",
        height: "74px",
        marginTop: "24px",
        padding: "16px",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "inline-flex" }}>
          <StatusChip status={model.status} />
        </Box>
        <IconButton
          onClick={handleClickArrow}
          sx={{
            display: "inline-flex",
            float: "right",
            marginTop: "-8px",
            marginRight: "-8px",
          }}
        >
          <ArrowOutwardIcon
            fill={colors.iconColor}
            width={"24px"}
            height={"24px"}
          />
        </IconButton>
      </Box>

      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <Typography
          sx={{
            color: colors.deviceCardHeaderFont,
            fontFamily: "NotoSans-Medium",
            fontSize: "16px",
            fontWeight: "600",
            letterSpacing: "0.02px",
            lineHeight: "17px",
          }}
        >
          {model.name}
        </Typography>
      </Box>
    </BasicCard>
  );
};

export default ModelCard;
