import { useContext } from "react";
import { useIntl } from "react-intl";
import { getSamplingFreqOptions, minute } from "../../../utils/utils";
import OptionCard from "../OptionCard";
import {
  getRequiredDateFormat,
  DarkModeContext,
} from "@datwyler/shared-components";

const Configuration = (props) => {
  const { model } = props;
  const intl = useIntl();

  let prediction_hours = 0;
  let prediction_minutes =
    (model.predictionHorizon * model.samplingFreq) / minute;
  if (prediction_minutes > 60) {
    prediction_hours = Math.floor(prediction_minutes / 60);
    prediction_minutes = prediction_minutes % 60;
  }

  const cardData = {
    prediction_horizon:
      prediction_hours > 0
        ? prediction_hours + " " + intl.formatMessage({ id: "hours" })
        : "" + prediction_minutes + " " + intl.formatMessage({ id: "minutes" }),
    lookback_period: model.lookBackPeriod,
    autotune_model: model.autotune?.toString(),
  };

  return <OptionCard title={"Configuration"} data={cardData} />;
};

export default Configuration;
