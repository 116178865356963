import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {
  BasicCard,
  DarkModeContext,
  useTelemetry,
  TenantIdContext,
  getEpochTime,
  BasicLineChart,
  getRequiredDateFormat,
  BasicCustomTooltip,
} from "@datwyler/shared-components";
import { useIntl } from "react-intl";

const mockCurrent = [
  {
    device: {
      id: "a876290d-d6ac-4071-a5de-c41183ca7103",
      name: "DCE RUTX11",
    },
    name: "modem_temperature",
    value: "45.0",
    unit: "°C",
    quality: "GOOD",
    type: "DOUBLE",
    time: 1711903286,
  },
];

const ForecastedData = (props: any) => {
  const { model } = props;
  const { colors }: any = useContext(DarkModeContext);
  const { tenantId }: any = useContext(TenantIdContext);
  const intl = useIntl();
  const parameter = model.parameter;
  const { fetchTelemetryData, fetchTelemetry, isFetchTelemetryLoading } =
    useTelemetry();
  const {
    fetchTelemetryData: fetchActualData,
    fetchTelemetry: fetchActual,
    isFetchTelemetryLoading: isFetchActualLoading,
  } = useTelemetry();
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    fetchTelemetry({
      variables: {
        tenantId: tenantId,
        deviceIds: [model.device?.id],
        startTime: model.startDate,
        endTime: model.endDate,
        page: 0,
        size: 99999, // set a huge page size
        sort: ["time,asc"],
        filter: [`name:${parameter}`],
      },
    });

    fetchActual({
      variables: {
        tenantId: tenantId,
        deviceIds: [model.device?.id],
        startTime: model.endDate,
        endTime: model.endDate + model.samplingFreq * model.predictionHorizon,
        page: 0,
        size: 99999, // set a huge page size
        sort: ["time,asc"],
        filter: [`name:${parameter}`],
      },
    });
  }, []);

  useEffect(() => {
    let trainingData = [];
    let actualData = [];
    let forecastData = [];

    if (fetchTelemetryData?.telemetries?.telemetries) {
      trainingData = generateData(
        fetchTelemetryData?.telemetries?.telemetries,
        intl.formatMessage({ id: parameter })
      );
    }

    if (fetchActualData?.telemetries?.telemetries) {
      actualData = generateData(
        fetchActualData?.telemetries?.telemetries,
        "actual"
      );

      // todo: replace with real forecast data when api is ready
      const mockforecastedData = JSON.parse(
        JSON.stringify(fetchActualData?.telemetries?.telemetries)
      );

      mockforecastedData.forEach((element) => {
        element.value = (35.0 + Math.random() * 3).toFixed(1).toString();
      });

      forecastData = generateData(mockforecastedData, "predicted");
      // todo: replace with real forecast data when api is ready
    }

    const chartData = trainingData.concat(actualData).concat(forecastData);

    setChartData(chartData);
  }, [fetchTelemetryData, fetchActualData]);

  const generateData = (dataFromDb = [], id) => {
    const data = [];
    let min = undefined;
    let max = undefined;
    let currTime = 0;

    dataFromDb.map((row) => {
      if (min === undefined) min = row.value;
      if (max === undefined) max = row.value;
      if (row.value > max) max = row.value;
      if (row.value < min) min = row.value;

      // for debugging sorting error with large data
      // if (row.time < currTime) {
      //   console.log("currTime unix", currTime);
      //   console.log("wrong time unix", row.time);
      //   console.log("currTime ", new Date(currTime * 1000));
      //   console.log("wrong time ", new Date(row.time * 1000));
      // } else {
      //   currTime = row.time;
      // }

      data.push({
        x: new Date(row.time * 1000),
        y: row.value,
      });
    });

    if (data.length < 1) {
      return [];
    }

    return [
      {
        id: id,
        data: data,
      },
    ];
  };

  const getTickValues = () => {
    const values = [];
    const tempChartData = chartData[0]?.data.concat(chartData[1]?.data);
    if (tempChartData) {
      const numTicks = 10;
      const max = tempChartData.length;
      const intervals = Math.floor(max / numTicks);

      if (intervals < 1) {
        tempChartData.forEach((d) => {
          values.push(d?.x);
        });
      } else {
        for (let i = 0; i < max; i = i + intervals) {
          values.push(tempChartData[i].x);
        }
      }
    }

    return values;
  };

  const chartMargin = {
    top: 50,
    right: 50,
    bottom: 50,
    left: 80,
  };

  const yScale = {
    type: "linear",
    min: 30,
    max: 40,
    reverse: false,
  };

  const sliceTooltip = ({ slice }) => {
    return <BasicCustomTooltip slice={slice} data={chartData} />;
  };

  return (
    <BasicCard sx={{ height: "506px", marginBottom: "24px" }}>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Typography
            sx={{
              color: colors.deviceCardHeaderFont,
              fontFamily: "NotoSans-Medium",
              fontSize: "16px",
              fontWeight: 500,
              letterSpacing: 0,
              lineHeight: "24px",
            }}
          >
            {"Forecasted Data"}
          </Typography>
        </Box>
        <Box sx={{ height: "458px" }}>
          {isFetchTelemetryLoading || isFetchActualLoading ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: colors.datwylerTeal,
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : chartData.length ? (
            <Box sx={{ width: "100%", height: "100%" }}>
              <BasicLineChart
                data={chartData}
                colors={[
                  colors.datwylerTeal,
                  colors.yellow500,
                  colors.green600,
                ]}
                keys={["actual', 'Temperature"]}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "bottom-left",
                    direction: "row",
                    justify: false,
                    translateX: 0,
                    translateY: 54,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 166,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 18,
                    symbolShape: "square",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    itemTextColor: colors.chartAxisFont,
                  },
                ]}
                curve={"linear"}
                axisRight={null}
                enablePoints={false}
                margin={chartMargin}
                yScale={yScale}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -15,
                  legend: intl.formatMessage({ id: "date" }),
                  legendOffset: 30,
                  legendPosition: "end",
                  tickValues: getTickValues(),
                  format: (value) =>
                    `${getRequiredDateFormat(value, "DD/MM/YY")}`, // using momentjs to format
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: !!parameter
                    ? intl.formatMessage({ id: parameter })
                    : "",
                  legendOffset: -70,
                  legendPosition: "middle",
                  fill: "red",
                }}
                enableSlices={"x"}
                sliceTooltip={sliceTooltip}
              />
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: 16, color: colors.widgetTableHeader }}
              >
                {intl.formatMessage({ id: "no_data" })}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </BasicCard>
  );
};

export default ForecastedData;
