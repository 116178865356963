import { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { getSamplingFreqOptions } from "../../../utils/utils";
import Grid from "@mui/material/Grid";
import { BasicCard, DarkModeContext } from "@datwyler/shared-components";

const OptionCard = (props) => {
  const { title, data } = props;
  const { colors }: any = useContext(DarkModeContext);
  const intl = useIntl();

  return (
    <BasicCard sx={{ marginBottom: "24px", cursor: "default" }}>
      <Box>
        <Typography
          sx={{
            color: colors.fontTitle2,
            fontFamily: "NotoSans-Medium",
            fontSize: "16px",
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: "24px",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ marginTop: "24px" }}>
        {Object.keys(data).map((key) => (
          <Grid container sx={{ marginTop: "8px" }}>
            <Grid item xs={6}>
              <Typography
                sx={{
                  color: colors.subTitle,
                  fontFamily: "NotoSans-Regular",
                  fontSize: "14px",
                  letterSpacing: 0,
                  lineHeight: "20px",
                }}
              >
                {intl.formatMessage({ id: key })}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  color: colors.textFieldLabel,
                  fontFamily: "NotoSans-Medium",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: 0,
                  lineHeight: "20px",
                }}
              >
                {data[key]}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </BasicCard>
  );
};

export default OptionCard;
