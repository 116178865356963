import { useContext } from "react";
import { useIntl } from "react-intl";
import { getSamplingFreqOptions } from "../../../utils/utils";
import OptionCard from "../OptionCard";
import {
  getRequiredDateFormat,
  DarkModeContext,
} from "@datwyler/shared-components";

const ForecastOption = (props) => {
  const { model } = props;
  const { colors }: any = useContext(DarkModeContext);
  const intl = useIntl();

  const getSamplingFreqLabel = () => {
    const samplingFreqOptions = getSamplingFreqOptions(intl);
    const option = samplingFreqOptions.find(
      (opt) => opt.value === model.samplingFreq
    );
    return option?.label || "";
  };

  const cardData = {
    sampling_frequency: getSamplingFreqLabel(),
    value: model.value,
    training_date:
      getRequiredDateFormat(model.startDate, "DD/MM/YY") +
      " - " +
      getRequiredDateFormat(model.endDate, "DD/MM/YY"),
  };

  return <OptionCard title={"Forecast Option"} data={cardData} />;
};

export default ForecastOption;
