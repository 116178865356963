import { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SourceConfiguration from "./SourceConfiguration";
import AnomalyDetectionOption from "./AnomalyDetectionOption";
import Configuration from "./Configuration";
import TrainingData from "./TrainingData";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import {
  BasicButton,
  DarkModeContext,
  useSnackbar,
} from "@datwyler/shared-components";

const ForecastSetup = (props: any) => {
  const { backToAnalytics } = props;
  const form = useForm();
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(true);

  const [
    autotune,
    lookBackPeriod,
    name,
    selectedDeviceId,
    parameter,
    predictionHorizon,
    samplingFreq,
    trainingDates,
    value,
  ] = form.watch([
    "autotune",
    "lookBackPeriod",
    "name",
    "selectedDeviceId",
    "parameter",
    "predictionHorizon",
    "samplingFreq",
    "trainingDates",
    "value",
  ]);

  useEffect(() => {
    if (
      autotune !== undefined &&
      lookBackPeriod &&
      name &&
      selectedDeviceId &&
      parameter &&
      predictionHorizon &&
      samplingFreq &&
      trainingDates?.startDate &&
      trainingDates?.endDate &&
      value
    ) {
      setIsDisabled(false);
    } else setIsDisabled(true);
  }, [
    autotune,
    lookBackPeriod,
    name,
    selectedDeviceId,
    parameter,
    predictionHorizon,
    samplingFreq,
    trainingDates,
    value,
  ]);

  const handleCancel = () => {
    backToAnalytics();
  };

  const handleSubmit = () => {
    const isValid = form.trigger();

    if (isValid) {
      // call API to submit

      // if response OK
      enqueueSnackbar("Model successfully created", {
        variant: "success",
      });
      backToAnalytics();
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <SourceConfiguration form={form} />
          <TrainingData form={form} />
        </Grid>
        <Grid item xs={5}>
          <AnomalyDetectionOption form={form} />
          <Configuration form={form} />
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "inline-flex", marginRight: "24px" }}>
          <BasicButton onClick={handleCancel} variant={"outlined"}>
            {intl.formatMessage({ id: "cancel" })}
          </BasicButton>
        </Box>
        <Box sx={{ display: "inline-flex" }}>
          <BasicButton
            onClick={handleSubmit}
            variant={"contained"}
            sx={{ backgroundColor: colors.datwylerTeal, height: "44px" }}
            disabled={isDisabled}
          >
            {intl.formatMessage({ id: "submit" })}
          </BasicButton>
        </Box>
      </Box>
    </>
  );
};

export default ForecastSetup;
