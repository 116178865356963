import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {
  BasicCard,
  DarkModeContext,
  useTelemetry,
  TenantIdContext,
  getEpochTime,
  BasicLineChart,
  getRequiredDateFormat,
  BasicCustomTooltip,
} from "@datwyler/shared-components";
import { useIntl } from "react-intl";

const TrainingData = (props: any) => {
  const { form } = props;
  const { colors }: any = useContext(DarkModeContext);
  const { tenantId }: any = useContext(TenantIdContext);
  const intl = useIntl();
  const trainingDates = form.watch("trainingDates");
  const selectedDeviceId = form.watch("selectedDeviceId");
  const parameter = form.watch("parameter");
  const { fetchTelemetryData, fetchTelemetry, isFetchTelemetryLoading } =
    useTelemetry();
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (
      trainingDates?.startDate &&
      trainingDates?.endDate &&
      selectedDeviceId &&
      parameter
    ) {
      const startDate = getEpochTime(
        trainingDates.startDate.hour(0).minute(0).second(0).millisecond(0)
      );
      const endDate = getEpochTime(
        trainingDates.endDate.hour(23).minute(59).second(59).millisecond(999)
      );

      fetchTelemetry({
        variables: {
          tenantId: tenantId,
          deviceIds: [selectedDeviceId],
          startTime: startDate,
          endTime: endDate,
          page: 0,
          size: 99999, // set a huge page size
          sort: ["time,asc"],
          filter: [`name:${parameter}`],
        },
      });
    } else {
      setChartData([]);
    }
  }, [trainingDates, selectedDeviceId, parameter]);

  useEffect(() => {
    if (fetchTelemetryData?.telemetries?.telemetries) {
      // console.log(
      //   "fetchTelemetryData?.telemetries?.telemetries",
      //   fetchTelemetryData?.telemetries?.telemetries
      // );
      const chartData = generateData(
        fetchTelemetryData?.telemetries?.telemetries,
        intl.formatMessage({ id: parameter })
      );
      setChartData(chartData);
    }
  }, [fetchTelemetryData]);

  const generateData = (dataFromDb = [], id) => {
    const data = [];
    let min = undefined;
    let max = undefined;
    let currTime = 0;

    dataFromDb.map((row) => {
      if (min === undefined) min = row.value;
      if (max === undefined) max = row.value;
      if (row.value > max) max = row.value;
      if (row.value < min) min = row.value;

      // for debugging sorting error with large data
      // if (row.time < currTime) {
      //   console.log("currTime unix", currTime);
      //   console.log("wrong time unix", row.time);
      //   console.log("currTime ", new Date(currTime * 1000));
      //   console.log("wrong time ", new Date(row.time * 1000));
      // } else {
      //   currTime = row.time;
      // }

      data.push({
        x: new Date(row.time * 1000),
        y: row.value,
      });
    });

    if (data.length < 1) {
      return [];
    }

    return [
      {
        id: id,
        data: data,
      },
    ];
  };

  const getTickValues = () => {
    const values = [];
    if (chartData[0]?.data) {
      const numTicks = 10;
      const max = chartData[0].data.length;
      const intervals = Math.floor(max / numTicks);

      if (intervals < 1) {
        chartData[0].data.forEach((d) => {
          values.push(d?.x);
        });
      } else {
        for (let i = 0; i < max; i = i + intervals) {
          values.push(chartData[0].data[i].x);
        }
      }
    }

    return values;
  };

  const chartMargin = {
    top: 50,
    right: 50,
    bottom: 50,
    left: 80,
  };

  const yScale = {
    type: "linear",
    min: "auto",
    max: "auto",
    reverse: false,
  };

  const sliceTooltip = ({ slice }) => {
    return <BasicCustomTooltip slice={slice} />;
  };

  return (
    <BasicCard sx={{ height: "506px", marginBottom: "24px" }}>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Typography
            sx={{
              color: colors.deviceCardHeaderFont,
              fontFamily: "NotoSans-Medium",
              fontSize: "16px",
              fontWeight: 500,
              letterSpacing: 0,
              lineHeight: "24px",
            }}
          >
            {intl.formatMessage({ id: "training_data" })}
          </Typography>
        </Box>
        <Box sx={{ height: "458px" }}>
          {isFetchTelemetryLoading ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: colors.datwylerTeal,
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : chartData.length ? (
            <Box sx={{ width: "100%", height: "100%" }}>
              <BasicLineChart
                data={chartData}
                colors={[colors.datwylerTeal]}
                legends={[]}
                curve={"linear"}
                axisRight={null}
                enablePoints={false}
                margin={chartMargin}
                yScale={yScale}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -15,
                  legend: intl.formatMessage({ id: "date" }),
                  legendOffset: 45,
                  legendPosition: "end",
                  tickValues: getTickValues(),
                  format: (value) =>
                    `${getRequiredDateFormat(value, "DD/MM/YYYY")}`, // using momentjs to format
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: !!parameter
                    ? intl.formatMessage({ id: parameter })
                    : "",
                  legendOffset: -70,
                  legendPosition: "middle",
                  fill: "red",
                }}
                enableSlices={"x"}
                sliceTooltip={sliceTooltip}
              />
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: 16, color: colors.widgetTableHeader }}
              >
                {intl.formatMessage({ id: "no_data" })}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </BasicCard>
  );
};

export default TrainingData;
