import { IDLE, TRAINING, QUEUE, FORECASTING, FINISHED } from "./constants";

export const getBackgroundColor = (status: string, colors: any) => {
  switch (status) {
    case IDLE:
      return colors.chipBgOrange;
    case TRAINING:
      return colors.chipBgBlue;
    case QUEUE:
      return colors.green50;
    case FORECASTING:
      return colors.purple50;
    case FINISHED:
      return colors.teal50;
    default:
      return colors.natural100;
  }
};

export const getLabelColor = (status: string, colors: any) => {
  switch (status) {
    case IDLE:
      return colors.chipFontOrange;
    case TRAINING:
      return colors.chipFontBlue;
    case QUEUE:
      return colors.green600;
    case FORECASTING:
      return colors.purple600;
    case FINISHED:
      return colors.teal600;
    default:
      return colors.black;
  }
};

export const minute = 60;

export const getSamplingFreqOptions = (intl) => {
  return [
    {
      priority: 0,
      label: intl.formatMessage({ id: "one_min" }),
      value: 1 * minute,
    },
    {
      priority: 1,
      label: intl.formatMessage({ id: "five_min" }),
      value: 5 * minute,
    },
    {
      priority: 2,
      label: intl.formatMessage({ id: "ten_min" }),
      value: 10 * minute,
    },
    {
      priority: 3,
      label: intl.formatMessage({ id: "thirty_min" }),
      value: 30 * minute,
    },
    {
      priority: 4,
      label: intl.formatMessage({ id: "one_hour" }),
      value: 60 * minute,
    },
    {
      priority: 5,
      label: intl.formatMessage({ id: "two_hours" }),
      value: 120 * minute,
    },
  ];
};
