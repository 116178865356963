import { useIntl } from "react-intl";
import OptionCard from "../OptionCard";
import { getRequiredDateFormat } from "@datwyler/shared-components";

const PredictionPerformance = (props) => {
  const { model } = props;
  const intl = useIntl();

  const cardData = {
    predict_last_execution_date: model.prediction?.executionTime
      ? getRequiredDateFormat(model.prediction?.executionTime, "DD/MM/YY")
      : "",
    predict_last_execution_time: model.prediction?.executionTime
      ? getRequiredDateFormat(model.prediction?.executionTime, "HH:mm:ss")
      : "",
    mean_abs_percentage_err: model.prediction?.meanAbsPercentageError,
    mean_abs_err: model.prediction?.meanAbsError,
    root_mean_err: model.prediction?.rootMeanError,
  };

  return <OptionCard title={"Prediction Performance"} data={cardData} />;
};

export default PredictionPerformance;
