import { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { getSamplingFreqOptions } from "../../../utils/utils";
import {
  BasicCard,
  DarkModeContext,
  BasicDateRange,
  BasicSelectWithCheckMark,
} from "@datwyler/shared-components";

const AnomalyDetectionOption = (props: any) => {
  const { form } = props;
  const { colors }: any = useContext(DarkModeContext);
  const trainingDates = form.watch("trainingDates");
  const intl = useIntl();

  const samplingFreqOptions = getSamplingFreqOptions(intl);

  const getValueOptions = () => {
    const valueOptions = [];
    for (let i = 1; i <= 10; i++) {
      valueOptions.push({
        priority: i,
        label: i,
        value: i,
      });
    }

    return valueOptions;
  };

  return (
    <BasicCard sx={{ height: "245px", marginBottom: "24px" }}>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Typography
            sx={{
              color: colors.deviceCardHeaderFont,
              fontFamily: "NotoSans-Medium",
              fontSize: "16px",
              fontWeight: 500,
              letterSpacing: 0,
              lineHeight: "24px",
            }}
          >
            {intl.formatMessage({ id: "anomaly_detection_option" })}
          </Typography>
        </Box>
        <Box sx={{ marginTop: "24px" }}>
          <BasicSelectWithCheckMark
            menuItems={samplingFreqOptions}
            form={form}
            formItemLabel={"samplingFreq"}
            label={intl.formatMessage({ id: "sampling_frequency" })}
            variant={"standard"}
            menuItemWidth={"100%"}
            SelectDisplayProps={{
              paddingTop: "7px",
              paddingBottom: "5px",
              height: "20px",
              backgroundColor: "unset",
              fontSize: "16px",
              letterSpacing: "0.15px",
              lineHeight: "20px",
              fontFamily: "NotoSans-Regular",
              paddingLeft: "0px",
            }}
            inputLabelSx={{
              fontFamily: "NotoSans-Regular",
              fontSize: "14px",
              letterSpacing: "0.13px",
              lineHeight: "20px",
              top: "2px",
            }}
            menuPropsSx={{
              maxWidth: "100%",
              marginTop: "8px",
            }}
          />
        </Box>
        <Box sx={{ marginTop: "22px" }}>
          <BasicSelectWithCheckMark
            menuItems={getValueOptions()}
            form={form}
            formItemLabel={"value"}
            label={intl.formatMessage({ id: "value" })}
            variant={"standard"}
            menuItemWidth={"100%"}
            SelectDisplayProps={{
              paddingTop: "2px",
              paddingBottom: "5px",
              height: "20px",
              backgroundColor: "unset",
              fontSize: "16px",
              letterSpacing: "0.15px",
              lineHeight: "20px",
              fontFamily: "NotoSans-Regular",
              paddingLeft: "0px",
            }}
            inputLabelSx={{
              fontFamily: "NotoSans-Regular",
              fontSize: "14px",
              letterSpacing: "0.13px",
              lineHeight: "20px",
              top: "0px",
            }}
            menuPropsSx={{
              maxWidth: "100%",
              marginTop: "8px",
            }}
          />
        </Box>
        <Box sx={{ marginTop: "34px" }}>
          <BasicDateRange
            form={form}
            formItemLabel={"trainingDates"}
            label={intl.formatMessage({ id: "training_date" })}
            textfieldVariant={"standard"}
            disableFuture={true}
            InputProps={{
              sx: {
                backgroundColor: "unset",
                fontSize: !!trainingDates ? "16px" : "14px",
                letterSpacing: "0.15px",
                lineHeight: "20px",
                paddingBottom: "2px",
              },
            }}
          />
        </Box>
      </Box>
    </BasicCard>
  );
};

export default AnomalyDetectionOption;
