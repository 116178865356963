import Grid from "@mui/material/Grid";
import Details from "./Details.tsx";
import ForecastOption from "./ForecastOption";
import Configuration from "./Configuration";
import TrainingPerformance from "./TrainingPerformance";
import PredictionPerformance from "./PredictionPerformance";
import ForecastedData from "./ForecastedData";

const ForecastDetail = (props) => {
  const { model } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={7}>
        <Details model={model} />
        <ForecastedData model={model} />
      </Grid>
      <Grid item xs={5}>
        <ForecastOption model={model} />
        <Configuration model={model} />
        <TrainingPerformance model={model} />
        <PredictionPerformance model={model} />
      </Grid>
    </Grid>
  );
};

export default ForecastDetail;
