import { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { ACTIVE } from "../../../utils/constants";
import { useIntl } from "react-intl";
import {
  BasicCard,
  DarkModeContext,
  BasicTextField,
  ClearIcon,
  useModel,
  BasicSelectWithCheckMark,
  useDevice,
  TenantIdContext,
} from "@datwyler/shared-components";

const SourceConfiguration = (props: any) => {
  const { form } = props;
  const { tenantId }: any = useContext(TenantIdContext);
  const { colors }: any = useContext(DarkModeContext);
  const [focused, setFocused] = useState("");
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [parameterOptions, setParameterOptions] = useState([]);
  const { isFetchModelsLoading, fetchModelsData, fetchModels } = useModel();
  const {
    isFetchLoading: isFetchDevicesLoading,
    fetchDeviceData,
    fetchDevices,
  } = useDevice();
  const intl = useIntl();

  const name = form.watch("name");
  const deviceType = form.watch("deviceType");
  const selectedDeviceId = form.watch("selectedDeviceId");

  useEffect(() => {
    fetchModels();
  }, []);

  useEffect(() => {
    if (fetchModelsData?.models) {
      const deviceTypeOptions = [];
      const allUniqueDeviceTypes = [];

      fetchModelsData?.models.forEach((model) => {
        if (!allUniqueDeviceTypes.includes(model.deviceType)) {
          allUniqueDeviceTypes.push(model.deviceType);
        }
      });

      allUniqueDeviceTypes.forEach((deviceType, index) => {
        deviceTypeOptions.push({
          priority: index,
          label: deviceType,
          value: deviceType,
        });
      });
      setDeviceTypeOptions(deviceTypeOptions);
    }
  }, [fetchModelsData]);

  useEffect(() => {
    if (deviceType) {
      fetchDevices({
        variables: {
          tenantId: tenantId,
          page: 0,
          size: 99999,
          filter: [`type:${deviceType},status:${ACTIVE}`],
        },
      });

      form.setValue("selectedDeviceId", null);
      form.setValue("parameter", null);
    }
  }, [deviceType]);

  useEffect(() => {
    if (fetchDeviceData?.devices?.devices) {
      const deviceOptions = [];
      fetchDeviceData?.devices?.devices.forEach((device, index) => {
        deviceOptions.push({
          priority: index,
          label: device.site.name + " - " + device.name,
          value: device.id,
        });
      });

      setDeviceOptions(deviceOptions);
    }
  }, [fetchDeviceData]);

  useEffect(() => {
    if (selectedDeviceId) {
      const parameterOptions = [];
      const selectedDevice = fetchDeviceData?.devices?.devices.find(
        (device) => device.id === selectedDeviceId
      );
      let filteredValues = selectedDevice?.telemetry?.filter(
        (data) =>
          data.name.substring(0, 3) !== "set" &&
          data.type?.toUpperCase() === "DOUBLE"
      );

      filteredValues = filteredValues.sort((a, b) => {
        var nameA = intl.formatMessage({ id: a.name }).toUpperCase();
        var nameB = intl.formatMessage({ id: b.name }).toUpperCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      });

      if (filteredValues && filteredValues.length > 0) {
        filteredValues.forEach((val, index) => {
          parameterOptions.push({
            priority: index,
            label: val.name,
            value: val.name,
          });
        });
      }

      setParameterOptions(parameterOptions);
    }
  }, [selectedDeviceId]);

  const getClearAdornment = (form, formItemLabel, colors) => {
    const handleOnclick = () => {
      form.setValue(formItemLabel, "");
    };

    return (
      <InputAdornment position="end">
        <IconButton onClick={handleOnclick}>
          <ClearIcon fill={colors.iconColor} />
        </IconButton>
      </InputAdornment>
    );
  };

  return (
    <BasicCard sx={{ height: "320px", marginBottom: "24px" }}>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Typography
            sx={{
              color: colors.deviceCardHeaderFont,
              fontFamily: "NotoSans-Medium",
              fontSize: "16px",
              fontWeight: 500,
              letterSpacing: 0,
              lineHeight: "24px",
            }}
          >
            {intl.formatMessage({ id: "source_configuration" })}
          </Typography>
        </Box>
        <Box sx={{ marginTop: "24px" }}>
          <BasicTextField
            label={intl.formatMessage({ id: "forecast_model_name" })}
            placeholder={intl.formatMessage({ id: "input_name" })}
            type={"text"}
            form={form}
            formItemLabel={"name"}
            variant={"standard"}
            onClick={() => setTimeout(() => setFocused("name"), 100)}
            onBlur={() => setTimeout(() => setFocused(""), 200)}
            InputProps={{
              sx: {
                backgroundColor: "unset",
                fontSize: "16px",
                letterSpacing: "0.15px",
                lineHeight: "20px",
              },
              endAdornment:
                focused === "name" && !!name
                  ? getClearAdornment(form, "name", colors)
                  : null,
            }}
            InputLabelProps={{
              sx: {
                fontSize: "14px",
              },
            }}
          />
        </Box>
        <Box sx={{ marginTop: "24px" }}>
          <BasicSelectWithCheckMark
            menuItems={deviceTypeOptions}
            form={form}
            formItemLabel={"deviceType"}
            label={intl.formatMessage({ id: "device_type" })}
            variant={"standard"}
            menuItemWidth={"100%"}
            SelectDisplayProps={{
              paddingTop: "2px",
              paddingBottom: "5px",
              height: "20px",
              backgroundColor: "unset",
              fontSize: "16px",
              letterSpacing: "0.15px",
              lineHeight: "20px",
              fontFamily: "NotoSans-Regular",
              paddingLeft: "0px",
            }}
            inputLabelSx={{
              fontFamily: "NotoSans-Regular",
              fontSize: "14px",
              letterSpacing: "0.13px",
              lineHeight: "20px",
              top: "0px",
            }}
            menuPropsSx={{
              maxWidth: "100%",
              marginTop: "8px",
            }}
            isLoading={isFetchModelsLoading}
          />
        </Box>
        <Box sx={{ marginTop: "24px" }}>
          <BasicSelectWithCheckMark
            menuItems={deviceOptions}
            form={form}
            formItemLabel={"selectedDeviceId"}
            label={intl.formatMessage({ id: "device_name" })}
            variant={"standard"}
            menuItemWidth={"100%"}
            SelectDisplayProps={{
              paddingTop: "2px",
              paddingBottom: "5px",
              height: "20px",
              backgroundColor: "unset",
              fontSize: "16px",
              letterSpacing: "0.15px",
              lineHeight: "20px",
              fontFamily: "NotoSans-Regular",
              paddingLeft: "0px",
            }}
            inputLabelSx={{
              fontFamily: "NotoSans-Regular",
              fontSize: "14px",
              letterSpacing: "0.13px",
              lineHeight: "20px",
              top: "0px",
            }}
            menuPropsSx={{
              maxWidth: "100%",
              marginTop: "8px",
            }}
            isLoading={isFetchDevicesLoading}
          />
        </Box>
        <Box sx={{ marginTop: "24px" }}>
          <BasicSelectWithCheckMark
            menuItems={parameterOptions}
            form={form}
            formItemLabel={"parameter"}
            label={intl.formatMessage({ id: "parameter_name" })}
            variant={"standard"}
            menuItemWidth={"100%"}
            SelectDisplayProps={{
              paddingTop: "2px",
              paddingBottom: "5px",
              height: "20px",
              backgroundColor: "unset",
              fontSize: "16px",
              letterSpacing: "0.15px",
              lineHeight: "20px",
              fontFamily: "NotoSans-Regular",
              paddingLeft: "0px",
            }}
            inputLabelSx={{
              fontFamily: "NotoSans-Regular",
              fontSize: "14px",
              letterSpacing: "0.13px",
              lineHeight: "20px",
              top: "0px",
            }}
            menuPropsSx={{
              maxWidth: "100%",
              marginTop: "8px",
            }}
            isLoading={isFetchDevicesLoading}
          />
        </Box>
      </Box>
    </BasicCard>
  );
};

export default SourceConfiguration;
